<template>
  <div v-if="survey">

    <h1>{{ survey.title }} <small>
      <download-excel
        class="btn btn-light mt-3 float-right"
        :data="surveyResponses"
        :fields="Object.keys(excelHeadings).reduce((ret, key) => {
          ret[excelHeadings[key]] = key;
          return ret;
        }, {})"
        :name="'n-zone-survery-results' + '.' + this.$moment().format('YYMMDDThhmm') + '.xls'"
        :title="excelTitle"
        :worksheet="'N Zone Survey Results'"
        type="xls"
      >
        <span :title="'Download Survey Results (XLS)'"><i class="fa fa-download"></i> Download All</span>
      </download-excel>
    </small></h1>
    <h4 class="text-info">Avg: {{ Math.round(responsesAvg) }} / CSAT: {{ csat() }} / NPS: {{ nps() }}</h4>
    <p>
      <b>Avg:</b> Average response to all (based on Rating column)<br />
      <b>CSAT:</b> Customer Satisfaction Score (based on Rating column)<br />
      <b>NPS:</b> Net Promoter Score (based on Rating column)
    </p>

    <p>
      <b>Rating:</b> Averaged rating of the response (0-10)<br />
      <span class="d-block" v-for="(question, index) in survey.questions"><b>Q{{ (index + 1) }}:</b> {{ question.label }}</span>
    </p>

    <v-client-table class="bg-light p-2 vue-table-table w-100" name="dataTableSurveyResults" :columns="columns"
      :data="surveyResponses" :options="options" :useVuex="true" id="surveyResultsTable" ref="survey-results-table">
      <div slot="id" slot-scope="props">
        <b-button size="sm" variant="info" @click="responseId = props.row.id">View</b-button>
      </div>
    </v-client-table>

    <!-- MODAL FOR VIEWING SURVEY RESPONSE -->
    <b-modal v-model="showResponse" @hide="responseId = null" size="lg" hide-footer>
      <template #modal-title>
        <span>Survey Response</span>
      </template>
      <div v-if="responseId">
        <Survey :surveyData="survey" :responseData="surveyResponses.find(r => r.id == responseId)" :readOnly="true" />
      </div>
    </b-modal>

  </div>
</template>
<script>
import Vue from 'vue'
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import { ClientTable } from 'vue-tables-2-premium'
import { mapGetters, mapActions } from 'vuex'
import Survey from '@/components/Survey.vue'
Vue.use(ClientTable)

export default {
  name: 'SurveyResults',
  components: {
    ClientTable,
    Survey
  },
  props: {
    surveyId: {
      type: [Number, String],
      required: true
    },
    userId: {
      type: String,
      required: false,
      default: null
    },
    year: {
      type: [Number, String],
      required: false,
      default: '2025'
    },
    month: {
      type: [Number, String],
      required: false,
      default: '4'
    },
    day: {
      type: [Number, String],
      required: false,
      default: null
    },
  },
  data() {
    return {
      // TABLE OPTIONS
      options: {
        debounce: 750,
        perPage: 60,
        perPageValues: [30, 60, 90],
        headings: {
          id: '',
          submittedOn: 'Submitted',
          user: 'User',
          surveyQuestionResponseAvg: 'Rating'
        },
        sortable: ['submittedOn', 'user', 'surveyQuestionResponseAvg', 'q1', 'q2', 'q3', 'q4', 'q5'],
        filterable: ['submittedOn', 'user'],
        sortIcon: { base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      responseId: null,
      showResponse: false,
      survey: null,
      surveyResponses: [],
    }
  },
  computed: {
    url() {
      return 'survey_responses'
    },
    columns() {
      let cols = ['id', 'submittedOn', 'user', 'surveyQuestionResponseAvg']
      for (let i = 0; i < this.survey.questions.length; i++) {
        cols.push('q' + (i + 1))
      }
      return cols
    },
    timeLine() {
      return this.day ? 'day' : 'month'
    },
    dateToStart() {
      return this.$moment(this.year + '-' + this.month + '-' + (this.day || 1)).startOf(this.timeLine)
    },
    dateToEnd() {
      return this.$moment(this.year + '-' + this.month + '-' + (this.day || this.dateToStart.daysInMonth())).endOf(this.timeLine)
    },
    responsesAvg() {
      return this.surveyResponses.length > 0 ? this.surveyResponses.reduce((acc, resp) => acc + resp.surveyQuestionResponseAvg, 0) / this.surveyResponses.length : 0
    },
    excelHeadings(){
      return {
        submittedOn: 'Submitted',
        user: 'User',
        surveyQuestionResponseAvg: 'Rating',
        q1: 'Q1',
        q2: 'Q2',
        q3: 'Q3'
      }
    },
    excelTitle(){
      let title = this.survey.title + ' Results' + '<br>'
      title += 'Avg: ' + Math.round(this.responsesAvg) + ' / CSAT: ' + this.csat() + ' / NPS: ' + this.nps() + '<br>'
      for (let i = 0; i < this.survey.questions.length; i++) {
        title += 'Q' + (i + 1) + ': ' + this.survey.questions[i].label + '<br>'
      }
      return title
    }
  },
  async mounted() {
    await this.doGetSurvey()
    this.doGetSurveyResponses()
  },
  watch: {
    responseId() {
      this.showResponse = !!this.responseId
    },
    year() {
      this.doGetSurveyResponses()
    },
    month() {
      this.doGetSurveyResponses()
    },
  },
  methods: {
    ...mapActions(['getSurvey', 'getSurveyResponses']),
    async doGetSurvey() {
      let resp = await this.getSurvey(this.surveyId)
      this.survey = resp
    },
    async doGetSurveyResponses() {
      let resp = await this.getSurveyResponses({
        pagination: false,
        survey: this.surveyId,
        user: this.userId,
        'submittedOn[after]': this.dateToStart.format('YYYY-MM-DD'),
        'submittedOn[before]': this.dateToEnd.format('YYYY-MM-DD')
      })
      this.surveyResponses = this.filterResponses(resp)
    },
    filterResponses(responses) {
      for (let i = 0; i < responses.length; i++) {
        responses[i].submittedOn = this.$moment(responses[i].submittedOn).format('MM/DD/YY h:mma')
        responses[i].user = responses[i].user.fullName
        responses[i].surveyQuestionResponseAvg = this.averageResponse(responses[i].surveyQuestionResponses)
        for (let j = 0; j < this.survey.questions.length; j++) {
          let question = this.survey.questions[j]
          let response = responses[i].surveyQuestionResponses.find(r => r.question == question['@id'])
          if (response) {
            responses[i]['q' + (j + 1)] = response.response
          } else {
            responses[i]['q' + (j + 1)] = null
          }
        }
      }
      return responses
    },
    averageResponse(responses) {
      const resp = Object.values(responses);
      if (resp.length === 0) {
        return 0;
      }
      const sum = resp.reduce((acc, resp) => acc + resp.response, 0);
      return Math.round(sum / resp.length);
    },
    nps () {
      let responses = this.surveyResponses
      let detractors = 0
      let promoters = 0
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].surveyQuestionResponseAvg <= 6) {
          detractors++
        } else if (responses[i].surveyQuestionResponseAvg >= 9) {
          promoters++
        }
      }
      return Math.round((promoters - detractors) / responses.length * 100)
    },
    csat () {
      let responses = this.surveyResponses
      let satisfied = 0
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].surveyQuestionResponseAvg >= 9) {
          satisfied++
        }
      }
      return Math.round(satisfied / responses.length * 100)
    },
  }
}
</script>